import React from 'react';
import banner from "../img/IA-aplicada-gestion-innovacion-150224.jpg";
import footer from "../img/footer-IA.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Inteligencia Artificial Generativa aplicada a la gestión de la innovación" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-info centrado" role="alert">
						<h5>Inteligencia Artificial Generativa aplicada a la gestión de la innovación</h5>
						<h6>Taller Formativo Digitalización</h6>
						<h7><strong>Fecha y horario:</strong> 15 de febrero de 2024, de 09:15 a 14:00</h7><br></br>
						<h7><strong>Lugar:</strong> Aula Parque Científico - Campus Universitario La Yutera (Palencia)</h7>
						<hr></hr>
						<strong>Objetivos de la Jornada:</strong>
						<p>El objetivo de este taller es sentar las bases teóricas de la IAG, para identificar dónde y cómo aplicar el modelo de Procesamiento del Lenguaje Natural de Chat GPT en situaciones reales de negocio. Esto no lo aprenderás solo a través de explicaciones teóricas, resolverás ejercicios prácticos a lo largo del taller para afianzar los conocimientos adquiridos.</p>
					</div>
				</div>
			</div>
			<div className="card text-white bg-info w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>09:15 Recepción y entrega de documentación</strong>
						</li>
						<li><strong>09:30 Presentación: Ayudas de ICE y Programa Centr@tec3</strong>
							<i>Carmelo Seco,  Director Delegación Territorial ICE Palencia</i>
							<i>María del Mar de Blas, OTRI Itagra CT</i>
							<i>Eva de la Gala, Adjunta Dirección  CETECE</i>
						</li>
						<li><strong>09:45 Presentación. Inteligencia artificial generativa. Historia y funcionamiento.</strong><br></br>
						</li>
						<li><strong>10:00 Herramientas de IA Generativa: ChatGPT, Dall-e, ChatPDF entre otras </strong><br></br>
						</li>
						<li><strong>10:15 La comunicación humano – GPT: Los promts. Tipos y funcionamiento. La capacidad de hacer preguntas.</strong><br></br>
						</li>
						<li><strong>10:30 Ejercicio práctico</strong>
						</li>
						<li><strong>11:10 Aplicando Design Thinking inverso a GPT3.5: casos de uso en tu día a día.</strong>
						</li>
						<li><strong>11:30 Descanso </strong><br></br>
						</li>
						<li><strong>12:00 El pensamiento crítico para la inteligencia aumentada</strong><br></br>
						</li>
						<li><strong>12:15 El lado oscuro de la IAG. Riesgos legales y precauciones</strong><br></br>
						</li>
						<li><strong>12:30 Ejercicio práctico</strong><br></br>
						</li>
						<li><strong>13:00 Presentación de los casos</strong>
						</li>
						<li><strong>13:30 Aprendizajes y explicación del plan de trabajo</strong>
						</li>
						<li><strong>14:00 Cierre de la Jornada</strong>
						</li>
						<li><strong>Plan de trabajo individual. Tutoría Q&A. Seguimiento del plan de trabajo (3-4 semanas después)</strong>
							<i>Impartido por Angela Medina, consultora de IA y datos de Innolandia.</i>
							<p>Requisitos:</p>
							<ul>•	Para realizar la práctica del taller, cada asistente llevará su propio dispositivo con acceso a Chat GPT el día del taller, ya sea una cuenta gratuita o una de pago <a href="https://openai.com/product" target="blank"> (https://openai.com/product).</a></ul>
							<ul>•	Vamos a utilizar la herramienta MIRO durante todo el curso. Recogeremos en la plataforma todas vuestras ideas y recursos que surjan y realizaremos ahí la mayoría de las actividades. Os dejamos un vídeo de cómo usar MIRO: <a href="https://www.youtube.com/watch?v=mqi5shw2r5A" target="blank">https://www.youtube.com/watch?v=mqi5shw2r5A.</a></ul>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 15 de febrero de 2024, de 09:15 a 14:00</p>
					<p className="card-text-left"> Aula Parque Científico - Campus Universitario La Yutera (Palencia)</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/1204" width="95%" height="900px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<img src={footer} className="banner" alt="Inteligencia Artificial Generativa aplicada a la gestión de la innovación" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i className="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i className="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home